<template>
  <div class="text-general row">
    <div class="col-sm-4 mb-3">
      <div class="list-group mb-3" role="tablist">
        <v-link
          v-for="project in projects"
          v-bind:key="project"
          v-bind:path="project"
          v-bind:class="{
            active: status !== 'empty' && project === current.name,
          }"
          class="list-group-item list-group-item-action"
          role="tab"
        >
          {{ project }}
        </v-link>
      </div>
    </div>

    <div class="col-sm-8 mb-3" v-if="status === 'empty'">
      <p class="lead">Please select a project...</p>
    </div>

    <div class="col-sm-8 mb-3" v-if="status === 'loading'">
      <spinner />
    </div>

    <div class="col-sm-8 mb-3" v-if="status === 'display'">
      <copy-box v-bind:text="current.clone" />
      <div class="card">
        <div class="card-body">
          <markdown :source="current.readme" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Markdown from "vue3-markdown-it";
import Spinner from "@/components/Spinner";
import CopyBox from "@/components/CopyBox";
import VLink from "@/navigation/VLink";

export default {
  name: "Projects",
  components: {
    Spinner,
    CopyBox,
    Markdown,
    VLink,
  },
  data() {
    return {
      projects: [],
      current: undefined,
      status: "loading",
    };
  },
  methods: {
    switch(project) {
      self.current = undefined;

      if (project === undefined) {
        this.status = "empty";
      } else {
        this.status = "loading";
        let self = this;
        this.apis.projects.getProjectInfo(project).then((info) => {
          self.current = info;
          self.status = "display";
        });
      }
    },
    _navigation_callback(key, path) {
      let project = undefined;
      if (path.length > 0) project = path[0];
      this.switch(project);
    },
    copyCommand() {
      navigator.clipboard.writeText(this.current.clone);
    },
  },
  mounted() {
    let self = this;
    this.apis.projects.getProjects().then((projects) => {
      self.projects = projects;
      self.status = "empty";
    });
    this.apis.navigation.subscribe(this._navigation_callback);
  },
  unmounted() {
    this.apis.navigation.unsubscribe(this._navigation_callback);
  },
};
</script>

<style scoped></style>
