<template>
  <div class="input-group mb-3">
    <input
      type="text"
      class="form-control"
      v-bind:class="{
        'is-valid': state === 'done',
      }"
      aria-describedby="button-copy"
      v-bind:value="text"
      disabled
    />
    <div class="input-group-append">
      <button
        id="button-copy"
        class="btn"
        v-bind:class="{
          'btn-outline-secondary': state === 'ready',
          'btn-outline-success': state === 'done',
        }"
        v-on:click="copy"
      >
        {{ button_label }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Spinner",
  props: ["text"],
  data() {
    return { state: "ready" };
  },
  computed: {
    button_label() {
      if (this.state == "ready") return "Copy";
      else return "Done";
    },
  },
  methods: {
    async copy() {
      await navigator.clipboard.writeText(this.text);
      this.state = "done";
      await new Promise((_) => setTimeout(_, 2000));
      this.state = "ready";
    },
  },
};
</script>

<style scoped></style>
